import styled from "styled-components"

export const Wrapper = styled.div`
  h1 {
    margin: 2rem 0 0;
  }
`

export const Container = styled.div`
  position: relative;
  
  #FloatingMenu {
    top: 0;
  }
`

export const Banner = styled.div`
  text-align: center;
`

export const MenuImage = styled.div`
  padding: 0 8rem;

  @media (max-width: 960px) {
    padding: 0 1rem;
  }
`
