import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import { graphql, useStaticQuery } from "gatsby"
import { Banner, Container, MenuImage, Wrapper } from "./style"
import { Text } from "../common"
import menuImg from "../../assets/images/Kakilang_menu_large.jpg"


export const Menu = () => {
  const data = useStaticQuery(
    graphql`
        query {
            contentfulShopInformation {
              menu {
                title
                file {
                  url
                }
              }
            },
            menuImg: file(relativePath: {eq: "Kakilang_menu_large.png"}) {
            childImageSharp {
              fluid(quality: 100, srcSetBreakpoints: [400, 800, 1200, 1600]) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          banner: file(relativePath: {eq: "banner_3.png"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
    `
  )

  const { menu } = data.contentfulShopInformation
  return (
    <Wrapper>
      <Banner >
        <Img fluid={data.banner.childImageSharp.fluid} alt="Kakilang food" />
      </Banner>
      <Container>
        <Text>Menu</Text>
        <MenuImage>
          <Zoom>
            <Img alt={menu.title} fluid={data.menuImg.childImageSharp.fluid} />
            {/*<img src={menuImg} alt={menu.title}/>*/}
          </Zoom>
        </MenuImage>
      </Container>
    </Wrapper>
)
}
