import React from "react"
import { SEO } from '../components/common';
import { Header, Base } from "../components/Layout"
import { Menu } from "../components/menu/menu"


const MenuPage = () => (
  <Base overlay={true}>
    <SEO title="Our Menu" description="Kakilang's menu" location="/menu"/>
    <Header />
    <Menu />
  </Base>
)

export default MenuPage
